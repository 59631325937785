import React, { useRef } from 'react';
import Header from './Header';
import ProductShowcase from './ProductShowcase';
import OurWork from './OurWork';

const ProductList = () => (
  <div className="flex justify-between py-4 px-6 border-b border-gray-200">
    <div className="text-sm text-gray-600">Chronos Platform<br/>Time Management AI</div>
    <div className="text-sm text-gray-600">Tempus Suite<br/>Productivity Tools</div>
    <div className="text-sm text-gray-600">Aion Analytics<br/>Predictive Insights</div>
    <div className="text-sm text-gray-600">Kairos Cloud<br/>Collaborative Workspace</div>
  </div>
);

const MainContent = ({ scrollToOurWork }) => (
  <div className="flex flex-col justify-center items-center h-screen">
    <h1 className="text-6xl font-bold text-center leading-tight">
      Mastering Time,<br/>
      Empowering<br/>
      Progress.
    </h1>
    <div className="mt-8 cursor-pointer" onClick={scrollToOurWork}>
      <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8 animate-bounce" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 14l-7 7m0 0l-7-7m7 7V3" />
      </svg>
    </div>
  </div>
);

const HomePage = () => {
  const ourWorkRef = useRef(null);

  const scrollToOurWork = () => {
    ourWorkRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className="min-h-screen flex flex-col">
      <Header scrollToOurWork={scrollToOurWork} />
      <div className="pt-16"> {/* Add padding-top to account for fixed header */}
        <MainContent scrollToOurWork={scrollToOurWork} />
        <div ref={ourWorkRef}>
          <OurWork />
        </div>
        <ProductShowcase />
      </div>
    </div>
  );
};

export default HomePage;