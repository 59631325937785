import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const useScrollToTop = () => {
  const navigate = useNavigate();

  const scrollToTop = useCallback(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleClick = useCallback((to) => (event) => {
    event.preventDefault();
    navigate(to);
    scrollToTop();
  }, [navigate, scrollToTop]);

  return handleClick;
};

export default useScrollToTop;