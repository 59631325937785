// App.js
import HomePage from "./components/HomePage";
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import NewsPage from "./components/News";
import StackPage from "./components/StackPage";
import ContactPage from "./components/ContactPage";  // Add this import
import Footer from "./components/Footer"; // Import the Footer component
import TermsAndConditionsPage from "./components/TermsAndConditionsPage";
import PrivacyPolicyPage from "./components/PrivacyPolicyPage";

const App = () => {
  return (
    <Router>
      <div className="min-h-screen flex flex-col">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/news" element={<NewsPage />} />
          <Route path="/stack" element={<StackPage />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />

          <Route path="/terms" element={<TermsAndConditionsPage />} />

          {/* Add more routes here as you create more pages */}
          {/* For example: */}
          {/* <Route path="/work" element={<WorkPage />} /> */}
          {/* <Route path="/company" element={<CompanyPage />} /> */}
          {/* <Route path="/careers" element={<CareersPage />} /> */}
        </Routes>
        <Footer /> {/* Add the Footer component here */}
      </div>
    </Router>
  );
};

export default App;
