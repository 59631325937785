import React from 'react';
import { Link } from 'react-router-dom';
import Header from './Header';

const FeaturedPost = ({ title, category, description, imageUrl }) => (
  <div className="mb-8">
    <p className="text-sm text-gray-500 mb-2">— {category}</p>
    <h3 className="text-2xl font-bold mb-2">{title}</h3>
    {imageUrl && (
      <img src={imageUrl} alt={title} className="w-full h-48 object-cover mb-4 rounded-lg" />
    )}
    <p className="text-gray-700 mb-4">{description}</p>
    <Link to="#" className="text-blue-600 hover:underline">Read more</Link>
  </div>
);

const FeaturedPostsPage = () => {
  const featuredPosts = [
    {
      title: "AI-Driven Time Management",
      category: "Technology",
      description: "Explore how our AI algorithms are revolutionizing personal and professional time management, leading to unprecedented productivity gains.",
      imageUrl: "/path/to/ai-image.jpg"
    },
    {
      title: "The Future of Remote Work",
      category: "Workplace Trends",
      description: "Discover Kairos' vision for the future of remote work and how our tools are shaping distributed team collaboration. Increase your workloads",
      imageUrl: "/path/to/remote-work-image.jpg"
    },
    {
      title: "Balancing Productivity",
      category: "Health & Productivity",
      description: "Learn about our holistic approach to time management that prioritizes both productivity and personal well-being.",
      imageUrl: "/path/to/wellbeing-image.jpg"
    }
  ];

  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <div className="flex-grow container mx-auto px-4 py-8">
        <h1 className="text-5xl font-bold mb-8 mt-12">Featured Insights</h1>
        <p className="text-xl mb-12">
          Dive into our latest thoughts on time management, productivity, and the future of work.
        </p>
        <div className="grid md:grid-cols-3 gap-8">
          {featuredPosts.map((post, index) => (
            <FeaturedPost key={index} {...post} />
          ))}
        </div>
        <Link to="/all-posts" className="block mt-12 text-blue-600 hover:underline">
          See all posts
        </Link>
      </div>
    </div>
  );
};

export default FeaturedPostsPage;