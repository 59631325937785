import React from 'react';

const ProductShowcase = () => {
  const products = [
    {
      number: '01',
      image: '/images/aiply.png',
      alt: 'Aiply',
      category: 'IOS APP',
      title: 'Aiply',
      subtitle: 'Autonomous timekeeping',
      description: 'Aiply goes beyond traditional job search platforms, providing a comprehensive suite of features designed to not only connect you with the right opportunities but also empower you with the knowledge and tools needed to secure your dream job.'
    },
    {
      number: '02',
      image: '/images/iris.png',
      alt: 'Iris Device',
      category: 'IOS APP',
      title: 'Iris AI',
      subtitle: 'AI Chat',
      description: "Chat seamlessly and effortlessly with Iris, the intelligent assistant designed to enhance your daily interactions. Iris combines advanced artificial intelligence, chatbot capabilities, and GPT-3's deep learning algorithms to provide an unmatched conversational experience."
    },
    {
      number: '03',
      image: '/images/insta.jpg',
      alt: 'Instagram Followers',
      category: 'IOS APP',
      title: 'Insta Stats',
      subtitle: 'Instant Audience Insights',
      description: "Say hello to InstaPulse, the powerful app that gives you a deeper look into your Instagram audience. Whether you're an influencer, a brand, or just an Instagram enthusiast, InstaPulse provides the insights you need to grow and engage with your followers like a pro!."
    },
    {
      number: '04',
      image: '/images/glow_up.png',
      alt: 'Glow Up',
      category: 'IOS APP',
      title: 'Glow Up',
      subtitle: 'Real Estate Inventory',
      description: 'Dive into key insights, critical viewpoints, and structured chapter and section breakdowns, empowering you to absorb wisdom from diverse genres and authors effortlessly.'
    }
  ];

  return (
    <div className="container mx-auto px-4 py-8">
      {products.map((product, index) => (
        <div key={index} className="mb-8">
          <div className="flex flex-col lg:flex-row items-center justify-center">
            {index % 2 === 0 ? (
              <>
                <div className="lg:w-1/2 flex justify-center">
                  <img src={product.image} alt={product.alt} className="w-full max-w-xs object-cover rounded-lg" />
                </div>
                <div className="lg:w-1/2 lg:pl-8 mt-6 lg:mt-0 flex flex-col items-center lg:items-start">
                  <span className="text-6xl font-bold">{product.number}</span>
                  <div className="text-center lg:text-left">
                    <p className="text-gray-500">{product.category}</p>
                    <h2 className="text-3xl font-bold mb-2">{product.title}</h2>
                    <p className="text-gray-500">{product.subtitle}</p>
                    <p className="mt-4 text-gray-700">{product.description}</p>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="lg:w-1/2 lg:pr-8 mt-6 lg:mt-0 flex flex-col items-center lg:items-end order-2 lg:order-1">
                  <span className="text-6xl font-bold">{product.number}</span>
                  <div className="text-center lg:text-right">
                    <p className="text-gray-500">{product.category}</p>
                    <h2 className="text-3xl font-bold mb-2">{product.title}</h2>
                    <p className="text-gray-500">{product.subtitle}</p>
                    <p className="mt-4 text-gray-700">{product.description}</p>
                  </div>
                </div>
                <div className="lg:w-1/2 flex justify-center order-1 lg:order-2">
                  <img src={product.image} alt={product.alt} className="w-full max-w-xs object-cover rounded-lg" />
                </div>
              </>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProductShowcase;