import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleOurWorkClick = () => {
    if (location.pathname === '/') {
      // If we're on the home page, try to scroll
      const ourWorkSection = document.getElementById('our-work');
      if (ourWorkSection) {
        ourWorkSection.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      // If we're not on the home page, navigate to home
      navigate('/');
    }
  };

  return (
    <header className="flex justify-between items-center py-4 px-6 border-b border-gray-200">
      <div className="flex items-center">
        <div className="flex items-center">
          <Link to="/" className="flex items-center">
            <img
              src="/images/company-logo.png"
              alt="Kairos Logo"
              className="h-10 w-auto mr-2"
            />
            <span className="text-xl font-bold text-gray-800">KAIROS</span>
          </Link>
        </div>
      </div>
      <nav>
        <ul className="flex space-x-6">
          <li>
            <button
              onClick={handleOurWorkClick}
              className="text-gray-600 hover:text-black"
            >
              Our Work
            </button>
          </li>
          <li>
            <Link to="/stack" className="text-gray-600 hover:text-black">
              Stack
            </Link>
          </li>
          <li>
            <Link to="/news" className="text-gray-600 hover:text-black">
              News
            </Link>
          </li>
          <li>
            <Link to="/contact" className="text-gray-600 hover:text-black">
              Contact
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export default Header;